
import { Options, Vue } from "vue-class-component";
import moment from "moment";
import { Notify } from "vant";
import GDMap from "./GDMap.vue";
import Compressor from 'compressorjs';

@Options({
  components: {
    [Notify.Component.name]: Notify.Component,
    GDMap,
  },
})
export default class SceneSpecimenResultSave extends Vue {
  private wx = require("weixin-js-sdk");
  private pictureUrl =
    "http://39.98.157.248:6031/api/lims/upload/viewSpecimenPicture";
  //private pictureUrl =    "http://localhost:6031/api/lims/upload/viewSpecimenPicture";

  private specimenForm: any = {};
  private fileList: any = [];
  private characterList = [];
  private packagingTypeList = [];
  private showPicker = false;
  private showCharacter = false;
  private showPackagingType = false;
  private currentDate = new Date();
  private idCardLoading = false;
  public created() {
    this.findSpecimenAndSceneItem(this.$route.query.id);
    const dictTreeTypeCodes = ["character", "packagingType"];
    this.getDictItemsList(dictTreeTypeCodes.join(","));
  }

  private getDictItemsList(codes: string) {
    const url = "/api/sys/getDictValueByTypes";
    this.axios
      .get(url, { params: { code: codes } })
      .then((res: any) => {
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          const dictTreeTypeItems = res.data;
          //获取严重性字典
          const characterType = dictTreeTypeItems.find(
            (item: { code: string }) => item.code === "character"
          );
          this.characterList = characterType.itemList;
          //获取可接受程度
          const packagingType = dictTreeTypeItems.find(
            (item: { code: string }) => item.code === "packagingType"
          );
          this.packagingTypeList = packagingType.itemList;
        }
      })
      .catch((err) => {
        this.$toast.fail(err);
      });
  }

  private onCharacterConfirm(character: any) {
    this.specimenForm.character = character.name;
    this.showCharacter = false;
  }

  private onPackagingTypeConfirm(ptype: any) {
    this.specimenForm.packagingType = ptype.name;
    this.specimenForm.specification = ptype.httpUrl;
    this.showPackagingType = false;
  }

  private findSpecimenAndSceneItem(id: any) {
    const url = "/api/lims/sampleSpecimen/getSpecimenAndSceneItem";
    this.axios
      .get(url, {
        params: {
          id,
          userId: this.$store.state.userInfo.id,
        },
      })
      .then((res: any) => {
        //关闭加载
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          this.specimenForm = res.data;
          this.specimenForm.collectTime = this.formatDateToDays(
            this.specimenForm.collectTime
          );
          if (
            this.specimenForm.pictureList &&
            this.specimenForm.pictureList.length > 0
          ) {
            for (const samplePicture of this.specimenForm.pictureList) {
              this.fileList.push({
                url: this.pictureUrl + "?id=" + samplePicture.id,
                id: samplePicture.id,
                isImage: true,
              });
            }
          }
        }
      })
      .catch((err) => {
        this.$toast.fail(err);
      });
  }

  private showDateTimePicker() {
    this.currentDate = new Date(this.specimenForm.collectTime);
    this.showPicker = true;
  }
  private showGDMap() {
    //打开样本详情
    const temp: any = this.$refs.gdMapRef;
    temp.showGdMap();
  }
  private cancelFn() {
    this.showPicker = false;
  }
  private confirmFn() {
    this.specimenForm.collectTime = moment(this.currentDate).format(
      "YYYY-MM-DD "
    );
    this.showPicker = false;
  }

  private formatDateToDays(time: any) {
    if (time) {
      return moment(time).format("YYYY-MM-DD");
    } else {
      return "";
    }
  }

  private saveBatchSpecimenModuleItem() {
    const specimenAndItem = JSON.parse(JSON.stringify(this.specimenForm));
    specimenAndItem.status = "5";
    specimenAndItem.collectTime = specimenAndItem.collectTime + " 00:00:00";
    const pList: any = [];
    for (const file of this.fileList) {
      console.log(file);
      if (file.id) {
        pList.push({ id: file.id });
      } else {
        pList.push({ name: file.file.name, content: file.content });
      }
    }
    specimenAndItem.pictureList = pList;
    const url = "/api/lims/sampleSpecimen/saveBatchSpecimenSceneModuleItem";
    this.axios
      .post(url, specimenAndItem)
      .then((res: any) => {
        if (res.status.code === 1) {
          Notify({ type: "success", message: "保存成功" });
          // location.href = "/sceneSpecimenListH5";
        } else {
          Notify(res.status.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private submitSpecimenModule() {
    const specimenAndItem = JSON.parse(JSON.stringify(this.specimenForm));
    specimenAndItem.status = "10";
    specimenAndItem.collectTime = specimenAndItem.collectTime + " 00:00:00";
    const pList: any = [];
    for (const file of this.fileList) {
      console.log(file);
      if (file.id) {
        pList.push({ id: file.id });
      } else {
        pList.push({ name: file.file.name, content: file.content });
      }
    }
    specimenAndItem.pictureList = pList;
    const url = "/api/lims/sampleSpecimen/saveBatchSpecimenSceneModuleItem";
    this.axios
      .post(url, specimenAndItem)
      .then((res: any) => {
        if (res.status.code === 1) {
          Notify({ type: "success", message: "提交成功" });
          location.href = "/sceneSpecimenListH5";
        } else {
          Notify(res.status.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  private getAress(locationStr: any, longitude: any, latitude: any) {
    this.specimenForm.address = locationStr;
    this.specimenForm.longitude = longitude;
    this.specimenForm.latitude = latitude;
  }
  //点击相机，选择图片或拍照
  private handleChooseImage() {
    // if (!this.perForm.org.id) {
    //   this.$toast.fail("请先选择采样机构！");
    //   return;
    // }
    const _that = this;
    this.wx.chooseImage({
      count: 1, // 默认9
      sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
      success: function (res: any) {
        _that.getLocalImgData(res.localIds[0].toString());
      },
    });
  }
  private getLocalImgData(localId: string) {
    this.idCardLoading = true;
    const _that = this;
    //获取本地图片
    this.wx.getLocalImgData({
      localId: localId,
      success: function (resL: any) {
        const localData = resL.localData;
        let imageBase64 = "";
        if (localData.indexOf("data:image") == 0) {
          //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
          imageBase64 = localData;
        } else {
          //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
          //此时一个正常的base64图片路径就完美生成赋值到img的src中了
          imageBase64 =
            "data:image/jpeg;base64," + localData.replace(/\n/g, "");
        }
        console.log(imageBase64);

        _that.specimenForm.samplePictureList.push(imageBase64);
      },
    });
  }

  // private afterRead(file){
  //   console.log('afterRead');
    
  //   console.log(file);
    
  // }
  


  //上传图片压缩,需要安装依赖： npm i compressorjs,并引入（import） 图片0.6倍压缩
   private onBeforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.6,
          success: resolve,
          error(err) {
            console.log('图片压缩失败---->>>>>',err.message);
          },
        });
      });
    }
}
