import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c527f72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "sceneSpecimenResultSave" }
const _hoisted_2 = { class: "table-box" }
const _hoisted_3 = { style: {"margin":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_row = _resolveComponent("van-row")!
  const _component_van_form = _resolveComponent("van-form")!
  const _component_van_action_bar_button = _resolveComponent("van-action-bar-button")!
  const _component_van_action_bar = _resolveComponent("van-action-bar")!
  const _component_van_datetime_picker = _resolveComponent("van-datetime-picker")!
  const _component_GDMap = _resolveComponent("GDMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_cell_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_van_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell_group, {
              inset: "",
              title: "样本信息"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.specimenForm.name) = $event)),
                  label: "样本名称",
                  placeholder: "样本名称",
                  "input-align": "right",
                  readonly: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.code,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.specimenForm.code) = $event)),
                  label: "样本编号",
                  placeholder: "样本编号",
                  "input-align": "right",
                  readonly: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.character,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.specimenForm.character) = $event)),
                  "is-link": "",
                  readonly: "",
                  label: "样品性状",
                  placeholder: "样品性状",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showCharacter = true)),
                  "input-align": "right"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_popup, {
                  show: _ctx.showCharacter,
                  "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showCharacter) = $event)),
                  round: "",
                  position: "bottom"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_van_picker, {
                      columns: _ctx.characterList,
                      teleport: "body",
                      "value-key": "name",
                      onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCharacter = false)),
                      onConfirm: _ctx.onCharacterConfirm
                    }, null, 8, ["columns", "onConfirm"])
                  ]),
                  _: 1
                }, 8, ["show"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.packagingType,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.specimenForm.packagingType) = $event)),
                  "is-link": "",
                  readonly: "",
                  label: "包装形式",
                  placeholder: "包装形式",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showPackagingType = true)),
                  "input-align": "right"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_popup, {
                  show: _ctx.showPackagingType,
                  "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showPackagingType) = $event)),
                  round: "",
                  position: "bottom"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_van_picker, {
                      columns: _ctx.packagingTypeList,
                      teleport: "body",
                      "value-key": "name",
                      onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showPackagingType = false)),
                      onConfirm: _ctx.onPackagingTypeConfirm
                    }, null, 8, ["columns", "onConfirm"])
                  ]),
                  _: 1
                }, 8, ["show"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.specification,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.specimenForm.specification) = $event)),
                  label: "规格/型号",
                  placeholder: "规格/型号",
                  "input-align": "right"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.address,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.specimenForm.address) = $event)),
                  type: "textarea",
                  "is-link": "",
                  label: "采样地址",
                  placeholder: "采样地址",
                  "input-align": "right",
                  onClick: _ctx.showGDMap
                }, null, 8, ["modelValue", "onClick"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.collectTime,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.specimenForm.collectTime) = $event)),
                  "is-link": "",
                  readonly: "",
                  label: "采样时间",
                  placeholder: "点击选择时间",
                  "input-align": "right",
                  onClick: _ctx.showDateTimePicker
                }, null, 8, ["modelValue", "onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_van_cell_group, {
              inset: "",
              title: "样本图片"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_uploader, {
                  modelValue: _ctx.fileList,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.fileList) = $event)),
                  multiple: "",
                  "before-read": _ctx.onBeforeRead
                }, null, 8, ["modelValue", "before-read"])
              ]),
              _: 1
            }),
            _createVNode(_component_van_cell_group, {
              inset: "",
              title: "项目信息"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_van_row, { class: "th-row" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_col, { span: 24/3 }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 项目名称 ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: 24/3 }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 结果 ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: 24/3 }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 单位 ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specimenForm.specimenModuleItemList, (item, index) => {
                    return (_openBlock(), _createBlock(_component_van_row, {
                      class: "tb-row",
                      key: index,
                      style: _normalizeStyle({background:index % 2 === 0 ?'#fff':'#F4F9FE'})
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_van_col, { span: 24/3 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_van_col, { span: 24/3 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_van_field, {
                              class: "checkResultClazz",
                              modelValue: item.checkResult,
                              "onUpdate:modelValue": ($event: any) => ((item.checkResult) = $event),
                              placeholder: "请输入结果"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_van_col, { span: 24/3 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.unit), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["style"]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_van_action_bar, null, {
        default: _withCtx(() => [
          _createVNode(_component_van_action_bar_button, {
            type: "success",
            text: "暂存",
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.saveBatchSpecimenModuleItem()))
          }),
          _createVNode(_component_van_action_bar_button, {
            type: "primary",
            text: "提交",
            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.submitSpecimenModule()))
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_van_popup, {
      show: _ctx.showPicker,
      "onUpdate:show": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.showPicker) = $event)),
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_datetime_picker, {
          modelValue: _ctx.currentDate,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.currentDate) = $event)),
          type: "date",
          onConfirm: _cache[17] || (_cache[17] = ($event: any) => (_ctx.confirmFn())),
          onCancel: _cache[18] || (_cache[18] = ($event: any) => (_ctx.cancelFn()))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_GDMap, {
      ref: "gdMapRef",
      onGetAress: _ctx.getAress
    }, null, 8, ["onGetAress"])
  ]))
}